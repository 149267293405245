<template>
  <v-app-bar
    aria-label="UQ Cadence Top Navigation"
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    color="secondary"
    app
    dark
    flat
  >
    <v-app-bar-nav-icon
      class="d-xl-flex"
      @click="changeDrawer"
    />
    <v-img
      class="hidden-sm-and-down"
      role="img"
      aria-labelledby="uq-cadence-banner-title"
      src="@/assets/images/logo_white.svg"
      max-height="40px"
      max-width="35px"
      contain
    />
    <v-toolbar-title
      id="uq-cadence-banner-title"
      class="ml-3"
    >
      <span class="hidden-sm-and-down">
        UQ Cadence
        <span v-if="getActiveAccount.showPreview">(Preview)</span>
      </span>
    </v-toolbar-title>
    <v-divider
      class="mx-4 hidden-sm-and-down"
      inset
      vertical
      dark
    />
    <v-toolbar-title
      v-if="getUserAccounts.length <= 1"
      class="text-subtitle-1 font-weight-regular ml-3"
    >
      {{ getActiveAccount.name }}
    </v-toolbar-title>

    <v-menu
      v-if="getUserAccounts.length > 1"
      v-model="accountMenu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-y
    >
      <template #activator="{ on, attrs }">
        <v-toolbar-title
          v-bind="attrs"
          aria-label="Account Menu Button"
          class="text-subtitle-1 font-weight-regular ml-3"
          v-on="on"
        >
          {{ getActiveAccount.name }}
          <span v-if="getActiveAccount.duplicateName && getActiveAccount.vertical">
            ({{ getActiveAccount.vertical }})
          </span>
          <v-icon
            small
            right
          >
            fas fa-caret-down
          </v-icon>
        </v-toolbar-title>
      </template>
      <v-card>
        <v-container>
          <v-text-field
            v-model="accountFilter"
            label="Search for Account"
            prepend-inner-icon="fal fa-search"
            outlined
            dense
            hide-details
            solo
            flat
          />
          <v-list
            class="overflow-y-auto"
            max-height="70vh"
            min-width="300"
          >
            <v-list-item
              v-for="item in filteredAccounts"
              :key="item.id"
              dense
              @click="changeAccount(item)"
            >
              <v-list-item-title
                class="text-wrap"
              >
                {{ item.name }}
                <span v-if="item.duplicateName && item.vertical">
                  ({{ item.vertical }})
                </span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-container>
      </v-card>
    </v-menu>

    <v-spacer />

    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          color="secondary"
          fab
          dark
          small
          depressed
          v-bind="attrs"
          aria-label="Sign out"
          v-on="on"
          @click.prevent="logout"
        >
          <v-icon dark>
            far fa-sign-out
          </v-icon>
        </v-btn>
      </template>
      <span>Sign out</span>
    </v-tooltip>
    <v-dialog
      v-model="changeAccountDialog"
      max-width="500"
      persistent
    >
      <v-card>
        <v-card-title>
          Account Changed
        </v-card-title>
        <v-card-text>
          You have signed into another account in Cadence. Cadence only allows a user to be
          signed into one account at a time. Click the button below to be redirected to your
          newly signed in account.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="changeAcountDialogClick"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'TopNav',
  data() {
    return {
      accountMenu: false,
      accountFilter: '',
      changeAccountDialog: false,
    };
  },
  computed: {
    ...mapState('navigation', ['sidebar']),
    ...mapGetters([
      'getActiveAccount',
      'getUserAccounts',
      'getRequireChangeAccount',
    ]),
    filteredAccounts() {
      if (this.accountFilter !== '') {
        return this.getUserAccounts.filter((x) => (x.name.toLowerCase()
          .includes(this.accountFilter.toLowerCase())));
      }
      return this.getUserAccounts;
    },
  },
  watch: {
    getRequireChangeAccount(newValue) {
      if (newValue === true) {
        this.changeAccountDialog = true;
      }
    },
  },
  methods: {
    async logout() {
      window.analytics.track('Logout', {
        accountId: this.getActiveAccount.id,
        name: this.getActiveAccount.name,
      });
      this.$router.push('/login');
      await this.$store.dispatch('logout');
      this.$zendesk.logout();
    },
    async changeAccount(item) {
      this.accountMenu = false;
      this.accountFilter = '';
      await this.$store.dispatch('USER_CHANGE_ACCOUNT', item.id);
      window.analytics.track('Change Account', {
        accountId: this.getActiveAccount.id,
        name: this.getActiveAccount.name,
      });
      window.analytics.group(this.getActiveAccount.id, {
        name: this.getActiveAccount.name,
        accountType: this.getActiveAccount.accountType,
      });
      this.$router.push('/accountChange');
    },
    changeDrawer() {
      this.$store.dispatch('navigation/sidebar/setOpen', !this.sidebar.open);
    },
    changeAcountDialogClick() {
      window.location.replace('/');
    },
  },
};
</script>
