const getters = {
  getProfile: (state) => state.profile,
  isProfileLoaded: (state) => state.profileLoaded,
  getFullName: (state) => `${state.profile.firstName} ${state.profile.lastName}`,
  getFormattedCodes: (state) => {
    if (state.profile.twoFactorRecoveryCodes) {
      return state.profile.twoFactorRecoveryCodes.map((code) => `${code.substring(0, 4)}-${code.substring(4, 8)}`);
    }
    return [];
  },
  systemAdmin: (state) => state.profile.systemAccessLevel === 'admin',
  systemUser: (state) => {
    if (state.profile.systemAccessLevel === 'admin'
      || state.profile.systemAccessLevel === 'accountManager') {
      return true;
    }
    return false;
  },
  accountAdmin: (state) => {
    if (state.profile.systemAccessLevel === 'admin'
      || state.profile.systemAccessLevel === 'accountManager'
      || state.activeAccount.accessLevel === 'admin') {
      return true;
    }
    return false;
  },
  isAccountsLoaded: (state) => state.accountsLoaded,
  getUserAccounts: (state) => state.accounts,
  getActiveAccount: (state) => state.activeAccount,
  getRequireChangeAccount: (state) => state.requireChangeAccount,
};

export default getters;
