<template>
  <ValidationProvider
    v-slot="{ errors }"
    rules="required"
    name="Timezone"
  >
    <v-select
      v-model="timezoneLocal"
      :items="timezones"
      :error-messages="errors"
      item-text="name"
      item-value="value"
      label="Timezone"
      @change="onChange"
    />
  </ValidationProvider>
</template>

<script>
export default {
  name: 'TimezoneSelect',
  props: {
    timezone: {
      type: String,
      required: false,
      default: '',
    },
    timezoneDisplay: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      timezoneLocal: this.timezone,
      timezones: [
        {
          name: 'Eastern Time',
          value: 'America/New_York',
        },
        {
          name: 'Central Time',
          value: 'America/Chicago',
        },
        {
          name: 'Mountain Time',
          value: 'America/Denver',
        },
        {
          name: 'Arizona Time',
          value: 'America/Phoenix',
        },
        {
          name: 'Pacific Time',
          value: 'America/Los_Angeles',
        },
        {
          name: 'Alaska Time',
          value: 'America/Anchorage',
        },
        {
          name: 'Hawaii-Aleutian Time',
          value: 'Pacific/Honolulu',
        },
        {
          name: 'UTC',
          value: 'Etc/UTC',
        },
      ],
    };
  },
  watch: {
    timezone: {
      handler(value) {
        this.timezoneLocal = value;
        this.updateDisplay();
      },
    },
  },
  mounted() {
    this.updateDisplay();
  },
  methods: {
    onChange() {
      this.$emit('update:timezone', this.timezoneLocal);
      this.updateDisplay();
    },
    updateDisplay() {
      if (this.timezoneLocal && this.timezoneLocal !== '') {
        this.$emit('update:timezoneDisplay', this.timezones.find((x) => x.value === this.timezoneLocal).name);
      }
    },
  },
};
</script>
