<template>
  <ValidationObserver ref="observer">
    <v-overlay :value="overlay">
      <v-progress-circular
        :size="70"
        :width="7"
        indeterminate
      />
    </v-overlay>
    <v-alert
      :value="noDestinations"
      type="error"
      :class="{'mt-2': $vuetify.breakpoint.smAndDown, 'ma-3': $vuetify.breakpoint.mdAndUp}"
    >
      <h4>
        There are no destinations set up for this account. You must first set up a destination
        before adding any sources.
      </h4>
      <router-link
        to="/connectors/destinations/create"
        class="white--text font-weight-bold"
      >
        Create a destination
      </router-link>
    </v-alert>
    <v-form
      class="mt-3"
      @submit.prevent="save"
    >
      <v-container
        grid-list-lg
        fluid
      >
        <v-expansion-panels
          v-model="panel"
          :multiple="true"
        >
          <v-expansion-panel>
            <v-expansion-panel-header>Source</v-expansion-panel-header>
            <v-expansion-panel-content>
              <ValidationObserver ref="sourceObserver">
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="required"
                      name="Name"
                    >
                      <v-text-field
                        v-model="name"
                        label="Name"
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <ValidationProvider
                      ref="frequencyValidation"
                      v-slot="{ errors }"
                      name="Frequency"
                    >
                      <v-select
                        v-model="frequency"
                        :items="frequencyList"
                        :error-messages="errors"
                        item-text="name"
                        item-value="value"
                        label="Start job every"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <v-menu
                      ref="menu"
                      v-model="timeMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="anchorTime"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template #activator="{ on }">
                        <v-text-field
                          v-model="anchorTime"
                          label="Anchor Time"
                          prepend-icon="access_time"
                          :prefix="timezoneDisplay"
                          :suffix="localTime"
                          readonly
                          v-on="on"
                        />
                      </template>
                      <v-time-picker
                        v-if="timeMenu"
                        v-model="anchorTime"
                        :allowed-minutes="allowedMinutes"
                        format="24hr"
                        width="290px"
                        @click:minute="$refs.menu.save(anchorTime)"
                      />
                    </v-menu>
                    <div class="caption">
                      Anchor time sets when the job is scheduled to run. The frequency uses this
                      time to determine when all subsequent jobs will run.  For jobs where certain
                      steps are only run daily anchor time determines what time those are run (i.e.
                      WooCommerce product pulls).
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <timezone
                      :timezone.sync="timezoneLocal"
                      :timezone-display.sync="timezoneDisplay"
                    />
                  </v-col>
                  <v-col
                    v-if="!id"
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <v-menu
                      v-model="startDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template #activator="{ on }">
                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                          name="Historical Date"
                        >
                          <v-text-field
                            v-model="startDate"
                            label="Sync Historical Data From"
                            prepend-icon="event"
                            readonly
                            :error-messages="errors"
                            :disabled="id !== ''"
                            v-on="on"
                          />
                        </ValidationProvider>
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        no-title
                        min="2000-01-01"
                        :max="new Date().toISOString()"
                        @input="startDateMenu = false"
                      />
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="required"
                      name="Source Type"
                    >
                      <v-select
                        v-model="sourceType"
                        :items="sortedTypes"
                        :error-messages="errors"
                        :disabled="id !== ''"
                        item-text="name"
                        item-value="value"
                        label="Source Type"
                        @change="sourceChange"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-card
                  v-if="sourceType"
                  outlined
                >
                  <v-card-text>
                    <span class="title">{{ componentTitle }} Detail</span>
                    <component
                      :is="detailComponent"
                      :id="id"
                      ref="sourceDetail"
                      v-bind="detail"
                      :error-message="errorMessage"
                      :is-new="id === ''"
                    />
                    <v-row v-show="[1, 8, 10, 14, 19].includes(sourceType)">
                      <v-col cols="12">
                        <v-btn
                          color="secondary"
                          @click="preview"
                        >
                          Preview Data
                        </v-btn>
                        <v-tabs
                          v-show="showPreview"
                          background-color="secondary"
                          dark
                          class="mt-2"
                        >
                          <v-tab>Orders</v-tab>
                          <v-tab>Customers</v-tab>
                          <v-tab>Products</v-tab>
                          <v-tab>Locations</v-tab>
                          <v-tab>Employees</v-tab>
                          <v-tab-item
                            :transition="false"
                            :reverse-transition="false"
                          >
                            <button
                              v-clipboard:copy="JSON.stringify(orders)"
                              type="button"
                            >
                              Copy To Clipboard
                            </button>
                            <div style="max-height:500px;overflow:auto;">
                              <pre>{{ orders }}</pre>
                            </div>
                          </v-tab-item>
                          <v-tab-item
                            :transition="false"
                            :reverse-transition="false"
                          >
                            <button
                              v-clipboard:copy="JSON.stringify(customers)"
                              type="button"
                            >
                              Copy To Clipboard
                            </button>
                            <div style="height:500px;overflow:auto;">
                              <pre>{{ customers }}</pre>
                            </div>
                          </v-tab-item>
                          <v-tab-item
                            :transition="false"
                            :reverse-transition="false"
                          >
                            <button
                              v-clipboard:copy="JSON.stringify(products)"
                              type="button"
                            >
                              Copy To Clipboard
                            </button>
                            <div style="height:500px;overflow:auto;">
                              <pre>{{ products }}</pre>
                            </div>
                          </v-tab-item>
                          <v-tab-item
                            :transition="false"
                            :reverse-transition="false"
                          >
                            <button
                              v-clipboard:copy="JSON.stringify(locations)"
                              type="button"
                            >
                              Copy To Clipboard
                            </button>
                            <div style="height:500px;overflow:auto;">
                              <pre>{{ locations }}</pre>
                            </div>
                          </v-tab-item>
                          <v-tab-item
                            :transition="false"
                            :reverse-transition="false"
                          >
                            <button
                              v-clipboard:copy="JSON.stringify(employees)"
                              type="button"
                            >
                              Copy To Clipboard
                            </button>
                            <div style="height:500px;overflow:auto;">
                              <pre>{{ employees }}</pre>
                            </div>
                          </v-tab-item>
                        </v-tabs>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </ValidationObserver>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
            :disabled="!sourceType"
          >
            <v-expansion-panel-header>Destinations</v-expansion-panel-header>
            <v-expansion-panel-content v-show="sourceType">
              <div
                v-if="loadingHistory"
                class="mb-2"
              >
                Selected destinations can not be changed while historical data is being loaded.
              </div>
              <ValidationObserver ref="destinationObserver">
                <div
                  v-show="!hasSources"
                  class="error--text mb-2"
                >
                  You must select at least one destination for this source.
                </div>
                <v-card
                  v-for="dest in destinations"
                  :key="dest.id"
                  class="mb-3"
                  color="blue-grey lighten-5"
                >
                  <v-card-text>
                    <destination
                      :id="dest.id"
                      :ref="dest.ref"
                      :destination="dest"
                      :source-type="sourceType || 0"
                      :selected="dest.selected"
                      :loading-history="loadingHistory"
                      :error-message="errorMessage"
                    />
                  </v-card-text>
                </v-card>
              </ValidationObserver>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
      <v-btn
        color="primary"
        type="submit"
        class="mt-2"
        :disabled="destinations.length === 0"
      >
        Save
      </v-btn>
      <v-btn
        type="submit"
        class="mt-2 ml-2"
        to="/connectors"
      >
        Cancel
      </v-btn>
    </v-form>
    <ValidationObserver ref="historyObserver">
      <v-dialog
        v-model="historyDialog"
        max-width="500"
        persistent
      >
        <v-card
          class="mx-auto"
          tile
        >
          <v-card-title>
            Pull Historical Data
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                New destinations have been added. Choose if you want to pull historical data for the
                new destinations.
              </v-col>
              <v-col cols="12">
                <ValidationProvider
                  rules=""
                  vid="pullHistory"
                >
                  <v-checkbox
                    v-model="pullHistory"
                    label="Pull Historical Data"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12">
                <v-menu
                  v-model="historicalDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template #activator="{ on }">
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="required_if:pullHistory,true"
                      name="Historical Date"
                    >
                      <v-text-field
                        v-model="historicalDate"
                        label="Sync Historical Data From"
                        prepend-icon="event"
                        readonly
                        :error-messages="errors"
                        v-on="on"
                      />
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                    v-model="historicalDate"
                    no-title
                    min="2000-01-01"
                    :max="new Date().toISOString()"
                    @input="historicalDateMenu = false"
                  />
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="historyDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="saveFinal"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </ValidationObserver>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex';
import BridalLive from './types/BridalLive';
import Rics from './types/Rics';
import Teamwork from './types/Teamwork';
import WooCommerce from './types/WooCommerce';
import BigCommerce from './types/BigCommerce';
import EnMotive from './types/EnMotive';
import Heartland from './types/Heartland';
import Oauth from './types/Oauth';
import AppointmentPlus from './types/AppointmentPlus';
import Waitwhile from './types/Waitwhile';
import Brightpearl from './types/Brightpearl';
import Runit from './types/Runit';
import Lightspeed from './types/Lightspeed';
import Shopify from './types/Shopify';
import FleetFeetOnline from './types/FleetFeetOnline';
import SkuVault from './types/SkuVault';
import Haku from './types/Haku';
import Destination from './Destination';
import sources from '../../_api/sources';
import store from '../../_store/sources';
import Timezone from './Timezone';

export default {
  name: 'SourceRoot',
  components: {
    BridalLive,
    Rics,
    Teamwork,
    WooCommerce,
    BigCommerce,
    EnMotive,
    Heartland,
    Oauth,
    AppointmentPlus,
    Waitwhile,
    Brightpearl,
    Lightspeed,
    Destination,
    Runit,
    Shopify,
    FleetFeetOnline,
    SkuVault,
    Haku,
    Timezone,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      timeMenu: false,
      name: '',
      detailComponent: '',
      componentTitle: '',
      sourceType: '',
      typeList: [
        {
          name: 'Rics',
          value: 1,
          component: 'Rics',
        },
        {
          name: 'WooCommerce',
          value: 2,
          component: 'WooCommerce',
        },
        {
          name: 'Teamwork',
          value: 3,
          component: 'Teamwork',
        },
        {
          name: 'Race Roster',
          value: 4,
          component: 'Oauth',
        },
        {
          name: 'RunSignup',
          value: 5,
          component: 'Oauth',
        },
        {
          name: 'Big Commerce',
          value: 6,
          component: 'BigCommerce',
        },
        {
          name: 'EnMotive',
          value: 7,
          component: 'EnMotive',
        },
        {
          name: 'Lightspeed',
          value: 8,
          component: 'Lightspeed',
        },
        {
          name: 'Heartland',
          value: 10,
          component: 'Heartland',
        },
        {
          name: 'Perkville',
          value: 11,
          component: 'Oauth',
        },
        {
          name: 'Appointment Plus',
          value: 12,
          component: 'AppointmentPlus',
        },
        {
          name: 'Waitwhile',
          value: 13,
          component: 'Waitwhile',
        },
        {
          name: 'Brightpearl',
          value: 9,
          component: 'Brightpearl',
        },
        {
          name: 'Runit',
          value: 14,
          component: 'Runit',
        },
        {
          name: 'Shopify',
          value: 15,
          component: 'Shopify',
        },
        {
          name: 'FleetFeet Online',
          value: 16,
          component: 'FleetFeetOnline',
        },
        {
          name: 'SKU Vault',
          value: 17,
          component: 'SkuVault',
        },
        {
          name: 'Haku',
          value: 18,
          component: 'Haku',
        },
        {
          name: 'Bridal Live',
          value: 19,
          component: 'BridalLive',
        },
      ],
      detail: {},
      errorMessage: '',
      anchorTime: '08:00',
      frequency: 1440,
      timezoneLocal: 'Etc/UTC',
      timezoneDisplay: 'UTC',
      startDate: '',
      startDateMenu: false,
      selectedDestinations: [],
      frequencyList: [
        {
          name: '10 minutes',
          value: 10,
        },
        {
          name: '20 minutes',
          value: 20,
        },
        {
          name: '30 minutes',
          value: 30,
        },
        {
          name: '1 hour',
          value: 60,
        },
        {
          name: '2 hours',
          value: 120,
        },
        {
          name: '3 hours',
          value: 180,
        },
        {
          name: '4 hours',
          value: 240,
        },
        {
          name: '6 hours',
          value: 360,
        },
        {
          name: '12 hours',
          value: 720,
        },
        {
          name: '24 hours',
          value: 1440,
        },
      ],
      panel: [0, 1],
      hasSources: true,
      noDestinations: false,
      overlay: true,
      showDestinations: false,
      historyDialog: false,
      historicalDateMenu: false,
      pullHistory: false,
      historicalDate: '',
      loadingHistory: false,
      showPreview: false,
      products: null,
      customers: null,
      orders: null,
      locations: null,
      employees: null,
    };
  },
  computed: {
    ...mapGetters([
      'getActiveAccount',
    ]),
    sortedTypes() {
      const copyList = this.typeList;
      return copyList.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    },
    destinations() {
      const data = [];
      this.$store.getters.destinations.forEach((dest) => {
        let selected = false;
        let detail = {};
        let startDate = null;
        let loadingHistory = false;
        this.selectedDestinations.forEach((selectDest) => {
          if (selectDest.id === dest.id) {
            selected = true;
            ({ detail, startDate, loadingHistory } = selectDest);
          }
        });
        data.push({
          id: dest.id,
          name: dest.name,
          destinationType: dest.destinationType,
          destinationDetail: dest.destinationDetail,
          selected,
          detail,
          ref: `destination${dest.id}`,
          isNew: !selected,
          startDate,
          loadingHistory,
        });
      });
      return data;
    },
    localTime() {
      if (this.timezoneLocal === 'Etc/UTC') {
        return `${this.getLocalTime(this.anchorTime)} Local Time`;
      }
      return '';
    },
  },
  beforeCreate() {
    const STORE_KEY = '$_sources';
    // eslint-disable-next-line no-underscore-dangle
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store);
    }
  },
  async created() {
    if (this.destinations.length === 0) {
      await this.$store.dispatch('DESTINATIONS_LOAD', this.getActiveAccount.id);
      if (this.destinations.length === 0) {
        this.noDestinations = true;
      }
    }
    if (this.id) {
      const resp = await sources.getSource(this.getActiveAccount.id, this.id);
      if (resp.data && Object.keys(resp.data).length !== 0) {
        this.sourceType = resp.data.sourceType;
        this.name = resp.data.name;
        this.detail = resp.data.detail;
        this.sourceChange(this.sourceType);
        this.anchorTime = resp.data.anchorTime.substring(0, 5);
        this.frequency = resp.data.frequencyMinutes;
        this.startDate = resp.data.startDate;
        this.timezoneLocal = resp.data.timezone;
        this.selectedDestinations = resp.data.destinations;
        if (resp.data.destinations.findIndex((e) => e.loadingHistory === true) >= 0) {
          this.loadingHistory = true;
        }
      } else {
        this.$router.push('/');
      }
    }
    this.overlay = false;
  },
  methods: {
    allowedMinutes: (m) => m % 10 === 0,
    sourceChange(value) {
      const type = this.typeList.find((item) => item.value === value);
      if (type) {
        this.detailComponent = type.component;
        this.componentTitle = type.name;
      } else {
        this.detailComponent = '';
        this.componentTitle = '';
      }
    },
    getLocalTime(value) {
      const [hour, minute] = value.split(':');
      let localHour = new Date(new Date().setUTCHours(hour, minute, 0, 0)).getHours().toString();
      if (localHour.length === 1) {
        localHour = `0${localHour}`;
      }
      return `${localHour}:${minute}`;
    },
    async save() {
      this.errorMessage = '';
      this.hasSources = true;
      const panel = [];
      let isValid = true;

      if (await this.$refs.sourceObserver.validate() === false) {
        panel.push(0);
      }

      if (this.sourceType !== 2 && this.frequency < 60) {
        this.$refs.frequencyValidation.setErrors(['The selected frequency is not supported for the selected source type']);
        panel.push(0);
      }

      let hasNewDestinations = false;

      if (await this.$refs.destinationObserver.validate() === false) {
        panel.push(1);
      } else {
        this.destinations.forEach((dest) => {
          if (this.$refs[dest.ref][0].isSelected() === true) {
            if (dest.isNew === true) {
              hasNewDestinations = true;
            }
          }
        });
      }

      if (panel.length > 0) {
        this.panel = panel;
        isValid = false;
      }

      if (isValid) {
        if (hasNewDestinations && this.id) {
          this.historyDialog = true;
        } else {
          await this.saveFinal();
        }
      }
    },
    async saveFinal() {
      let save = true;
      if (this.historyDialog) {
        save = await this.$refs.historyObserver.validate();
      }
      if (save) {
        this.historyDialog = false;
        const saveDestinations = [];
        this.destinations.forEach((dest) => {
          if (this.$refs[dest.ref][0].isSelected() === true) {
            saveDestinations.push({
              id: dest.id,
              detail: this.$refs[dest.ref][0].getDetail(),
            });
          }
        });
        if (saveDestinations.length === 0) {
          if (await this.$confirm('You have not selected any destinations, are you sure you want to continue?') === false) {
            return;
          }
        }
        this.overlay = true;
        try {
          if (this.id) {
            await sources.updateSource(
              this.getActiveAccount.id,
              this.id,
              this.name,
              this.$refs.sourceDetail.getDetail(),
              this.frequency,
              this.anchorTime,
              saveDestinations,
              this.pullHistory,
              this.historicalDate,
              this.timezoneLocal,
            );
          } else {
            await sources.createSource(
              this.getActiveAccount.id,
              this.name,
              this.sourceType,
              this.$refs.sourceDetail.getDetail(),
              this.frequency,
              this.anchorTime,
              this.startDate,
              saveDestinations,
              this.timezoneLocal,
            );
          }
          this.$store.dispatch('DESTINATIONS_LOAD', this.getActiveAccount.id);
          this.$router.push('/connectors');
        } catch (error) {
          this.overlay = false;
          if (error.response) {
            this.panel = [0, 1];
            this.errorMessage = error.response.data;
          }
        }
      }
    },
    async preview() {
      try {
        this.overlay = true;
        const data = await this.$refs.sourceDetail.preview();
        if (data) {
          this.showPreview = true;
          this.products = data.products || 'No Data';
          this.customers = data.customers || 'No Data';
          this.orders = data.orders || 'No Data';
          this.locations = data.locations || 'No Data';
          this.employees = data.employees || 'No Data';
        }
        this.overlay = false;
      } catch (e) {
        // ignore
        this.overlay = false;
      }
    },

  },
};
</script>
